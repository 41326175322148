import React, {
  useState,
} from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  Form as RouterForm,
  useLoaderData,
} from 'react-router-dom';

export default function CreateAssistantView() {
  const [models, teams] = useLoaderData();
  const [name, setName] = useState('');
  const [assistantModelId, setAssistantModelId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [description, setDescription] = useState('');

  return (
    <Container>
      <h1>Create Assistant</h1>
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          <Card className="mt-3 mb-3">
            <Card.Header>Assistant Information</Card.Header>
            <Card.Body>
              <Form as={RouterForm} method="PUT">
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Name</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      required
                      placeholder="Assistant Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Model</Form.Label>
                  <Col sm={10}>
                    <Form.Select
                      name="assistantModelId"
                      value={assistantModelId}
                      required
                      onChange={(e) => setAssistantModelId(e.target.value)}
                    >
                      {models.map((model) => (
                        <option value={model.id} key={`model-${model.id}`}>{model.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Team</Form.Label>
                  <Col sm={10}>
                    <Form.Select
                      name="teamId"
                      value={teamId}
                      required
                      onChange={(e) => setTeamId(e.target.value)}
                    >
                      {teams.rows.map((team) => (
                        <option value={team.id} key={`team-${team.id}`}>{team.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Description</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Describe this Assistant"
                    />
                  </Col>
                </Row>
                <Button type="submit">Create</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
