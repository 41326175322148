import React, {
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import Markdown from 'react-markdown';

import Badge from 'react-bootstrap/Badge';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';

import {
  Link,
  useLoaderData,
} from 'react-router-dom';
import request from '../../utils/request';

export default function HistoryView() {
  const [assistant, initialHistory] = useLoaderData();
  const [history, setHistory] = useState(initialHistory);
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);
  const limit = 10;
  const pages = Math.ceil(history.count / limit);

  const pageList = [
    page - 2,
    page - 1,
    page,
    page + 1,
    page + 2,
  ].filter(
    (i) => i >= 0 && i < pages,
  );

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      return;
    }

    const fetchData = async () => {
      const data = await request(`/api/assistant/${assistant.id}/history?p=${page}`);
      setHistory(data);
    };

    fetchData();
  }, [page]);

  return (
    <Container className="history-view">
      <h1>History</h1>
      <Breadcrumb className="mb-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/teams' }}>Teams</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistant.id}` }}>{assistant.name}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistant.id}/history` }} active>History</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm="4">
          <h4 className="text-center">
            <span>Conversations</span>
            &nbsp;
            <span className="fst-italic">
              (
              {history.count}
              )
            </span>
          </h4>
          <ListGroup className="mb-2">
            {history.rows.map((item) => (
              <ListGroup.Item
                key={item.id}
                role="button"
                onClick={() => setSelected(item)}
                active={selected && selected.id === item.id}
              >
                <div className="fw-bold text-truncate">{item.AssistantThreadMessages[1].content}</div>
                <div>{dayjs(item.createdAt).format('YYYY-MM-DD h:mm:ss a')}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {pages > 1 && (
          <Pagination>
            <Pagination.First onClick={() => setPage(0)} disabled={page === 0} />
            <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 0} />

            {pages > 5 && pageList[0] !== 0 && (
              <Pagination.Ellipsis disabled />
            )}

            {pageList.map((i) => (
              <Pagination.Item
                key={i}
                active={i === page}
                onClick={() => setPage(i)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            {pages > 5 && (pageList[pageList.length - 1] + 1) < pages && (
              <Pagination.Ellipsis disabled />
            )}

            <Pagination.Next onClick={() => setPage(page + 1)} />
            <Pagination.Last onClick={() => setPage(pages - 1)} />
          </Pagination>
          )}
        </Col>
        <Col sm="8">
          <h4 className="text-center">Details</h4>
          {selected && (
          <ListGroup>
            {selected.AssistantThreadMessages.map((message) => (
              <ListGroup.Item
                key={message.id}
                className={`
                    ${message.role === 'system' ? 'bg-info text-white' : ''}
                    ${message.role === 'user' ? 'bg-light' : ''}
                    `}
              >
                <div>
                  <span className="fw-bold text-capitalize">{message.role}</span>
                  <span>
                    &nbsp;
                    -
                    &nbsp;
                    {dayjs(message.createdAt).format('YYYY-MM-DD h:mm:ss a')}
                  </span>
                </div>
                <div>
                  <Markdown>{message.content}</Markdown>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          )}
          {!selected && (
          <Row>
            <h5 className="text-center fst-italic mb-4 pb-4 border-bottom">Select a conversation on the left to see the transcript.</h5>
            <Col sm={{ span: 4, offset: 4 }}>
              <Card>
                <Card.Img variant="top" src="https://www.jea.com/cdn/images/avatar/avatar-alt.svg" />
                <Card.Body>
                  <Card.Title>{assistant.name}</Card.Title>
                  <Card.Text>
                    <strong>Team:</strong>
                &nbsp;
                    <Badge>{assistant.teamName}</Badge>
                  </Card.Text>
                  <Card.Text>{assistant.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
