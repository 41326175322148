import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function CardActions({
  title,
  actions,
  children,
}) {
  return (
    <Card className="card-actions">
      <Card.Header className="d-flex justify-content-between">
        <strong>{title}</strong>
        {!!actions && !!actions.length && (
        <div>
          {actions.map((action) => (
            <OverlayTrigger
              key={action.key}
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip className="position-fixed">{action.tooltip}</Tooltip>}
            >
              <Button
                size="sm"
                variant={action.variant || 'primary'}
                onClick={action.onClick}
                data-testid={action.key}
              >
                {action.icon}
              </Button>
            </OverlayTrigger>
          ))}
        </div>
        )}
      </Card.Header>
      {children}
    </Card>
  );
}

CardActions.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      icon: PropTypes.node,
      tooltip: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.node.isRequired,
};

CardActions.defaultProps = {
  title: '',
  actions: null,
};
