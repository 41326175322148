import React, {
  useMemo,
} from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  Link,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import ActiveTable from '../../components/active-table/active-table';
import CardActions from '../../components/card-actions/card-actions';
import InfoIcon from '../../components/info-icon/info-icon';

const memberHeaders = [
  {
    key: 'firstName',
    title: 'Name',
  },
  {
    key: 'email',
    title: 'Email',
    className: 'text-truncate',
  },
];

export default function TeamView() {
  const memberActions = useMemo(() => ([
    {
      key: 'invite-member',
      icon: <FontAwesomeIcon icon={faPlus} />,
      tooltip: 'Invite a new member to the team.',
      variant: 'outline-primary',
      onClick: () => {},
    },
  ]), []);

  return (
    <Container>
      <h1>
        <span>Team Overview</span>
        &nbsp;
        <InfoIcon
          size="2xs"
          tooltip="Teams allow a group of users to collaborate and managea A.I. Assistants"
        />
      </h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/teams' }}>Teams</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/team' }} active>Team</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col sm={9}>
          <CardActions title="Assistants">
            <Card.Body className="bg-secondary">
              <Row xs={1} sm={2} md={3}>
                <Col>
                  <Card>
                    <Card.Img variant="top" />
                    <Card.Body>
                      <Card.Title>Create Assistant</Card.Title>
                      <Card.Text>
                        Create a new A.I. Assistant.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>

          </CardActions>
        </Col>
        <Col sm={3}>
          <ActiveTable
            title="Members"
            actions={memberActions}
            headers={memberHeaders}
            totalRows={1}
            rows={[
              {
                firstName: 'Paul',
                email: 'paulforbes42@gmail.com',
              },
            ]}
            hideFooter
          />
        </Col>
      </Row>
    </Container>
  );
}
