import React, {
  useState,
} from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import {
  Form as RouterForm,
  Link,
  useLoaderData,
} from 'react-router-dom';

import AssistantCard from '../../components/assistant-card/assistant-card';

export default function AssistantView() {
  const [apiKeyName, setApiKeyName] = useState('');
  const [assistant, keys] = useLoaderData();
  const [name, setName] = useState(assistant.name);
  const [description, setDescription] = useState(assistant.description);

  return (
    <Container>
      <h1>Assistant</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/assistants' }}>Assistants</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistant.id}` }} active>{assistant.name}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col md={3}>
          <AssistantCard assistant={assistant} />
        </Col>
        <Col md={9}>
          <Card className="mb-3">
            <Card.Header>Actions</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className="d-flex flex-row gap-3 align-items-center">
                  <Button className="col-2" as={Link} to={`/assistant/${assistant.id}/chat`}>Chat</Button>
                  <div className="col-10">Test the Assistant with a chat based conversation.</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-row gap-3 align-items-center">
                  <Button className="col-2" as={Link} to={`/reports?assistantId=${assistant.id}`}>Reports</Button>
                  <div className="col-10">Review usage metrics.</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-row gap-3 align-items-center">
                  <Button className="col-2" as={Link} to={`/assistant/${assistant.id}/history`}>History</Button>
                  <div className="col-10">See what people are talking with your assistant about.</div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex flex-row gap-3 align-items-center">
                  <Button className="col-2" as={Link} to={`/assistant/${assistant.id}/embedding`}>Embedding</Button>
                  <div className="col-10">Create a reference document that the model will read.</div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>Assistant Information</Card.Header>
            <Card.Body>
              <Form as={RouterForm} method="PUT">
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">ID</Form.Label>
                  <Col sm={10}>
                    <Form.Text>{assistant.id}</Form.Text>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Model</Form.Label>
                  <Col sm={10}>
                    <Form.Text>{assistant.assistantModelName}</Form.Text>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Name</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label className="col-sm-2" column="sm">Description</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Row>
                <Button type="submit" name="intent" value="assistant-information">Save</Button>
              </Form>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>Model Parameters</Card.Header>
            <Card.Body>
              <Form as={RouterForm} method="PUT">
                {assistant.parameters.map((param) => (
                  <Row className="mb-3" key={`model-parameter-${param.id}`}>
                    <Form.Label className="col-sm-2" column="sm">{param.name}</Form.Label>
                    <Col sm={10}>
                      <Form.Group>
                        {param.datatype === 'boolean' && (
                          <Form.Check
                            name={param.id}
                            defaultValue={param.currentValue || ''}
                          />
                        )}

                        {param.datatype === 'text' && (
                          <Form.Control
                            as="textarea"
                            name={param.id}
                            defaultValue={param.currentValue || ''}
                          />
                        )}

                        {param.datatype === 'integer' && (
                          <Form.Control
                            type="number"
                            name={param.id}
                            defaultValue={param.currentValue || ''}
                          />
                        )}

                        {param.datatype === 'float' && (
                          <Form.Control
                            type="number"
                            min={param.minValue}
                            max={param.maxValue}
                            name={param.id}
                            step="0.01"
                            defaultValue={param.currentValue || ''}
                          />
                        )}

                        <Form.Text>{param.description}</Form.Text>
                        <br />
                        <Form.Text>
                          Default:
                          {param.defaultValue}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                ))}
                <Button type="submit" name="intent" value="model-parameters">Save</Button>
              </Form>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>API Keys</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {keys.map((k) => (
                  <ListGroup.Item>
                    {k.title}
                    {' '}
                    -
                    {' '}
                    {k.createdAt}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <hr />
              <Row>
                <Col md={2}>
                  New API Key:
                </Col>
                <Col>
                  <Form as={RouterForm} method="POST">
                    <InputGroup>
                      <Form.Control
                        placeholder="API Key Name..."
                        onChange={(e) => setApiKeyName(e.target.value)}
                        value={apiKeyName}
                        name="title"
                        required
                      />
                      <Button type="submit" name="intent" value="api-key">Create</Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
