import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

export default function AssistantCard({ assistant }) {
  return (
    <Card>
      <Card.Img variant="top" src="https://www.jea.com/cdn/images/avatar/avatar-alt.svg" />
      <Card.Body>
        <Card.Title>{assistant.name}</Card.Title>
        <Card.Text>
          <strong>Team:</strong>
      &nbsp;
          <Badge>{assistant.teamName}</Badge>
        </Card.Text>
        <Card.Text>{assistant.description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

AssistantCard.propTypes = {
  assistant: PropTypes.shape({
    name: PropTypes.string,
    teamName: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};
