import React, {
  useCallback,
  useState,
} from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
// import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import Markdown from 'react-markdown';

import {
//  Form as RouterForm,
  useLoaderData,
} from 'react-router-dom';

import request from '../../utils/request';

export default function ChatView() {
  const assistant = useLoaderData();
  const [prompt, setPrompt] = useState('');
  const [message, setMessage] = useState(null);

  const handleChat = useCallback((e) => {
    e.preventDefault();

    const fetchData = async () => {
      const res = await request(`/api/assistant/${assistant.id}/completion/create`, {
        method: 'POST',
        body: {
          prompt,
        },
      });

      setMessage(res);
    };

    fetchData();
    setPrompt('');
  }, [prompt]);

  return (
    <Container className="chat-view">
      <h1 className="border-bottom">Chat</h1>
      <Row>
        <Col md={3}>
          <Card>
            <Card.Img variant="top" src="https://www.jea.com/cdn/images/avatar/avatar-alt.svg" />
            <Card.Body>
              <Card.Title>{assistant.name}</Card.Title>
              <Card.Text>
                <strong>Team:</strong>
                &nbsp;
                <Badge>{assistant.teamName}</Badge>
              </Card.Text>
              <Card.Text>{assistant.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Form onSubmit={handleChat}>
            <InputGroup>
              <Form.Control
                placeholder="Type a message to send to the assistant"
                onChange={(e) => setPrompt(e.target.value)}
              />
              <Button type="submit">Send</Button>
            </InputGroup>
          </Form>
          {message && (
          <Card>
            <Card.Body>
              <Card.Title>Response:</Card.Title>
              <Markdown>{message.message?.content}</Markdown>
            </Card.Body>
          </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}
