import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import {
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

export default function HomeView() {
  const assistants = useLoaderData();
  const navigate = useNavigate();

  return (
    <Container>
      <h1 className="border-bottom">Dashboard</h1>

      <h2>
        <span>Assistants</span>
      </h2>
      <Row md={4}>

        <Col className="d-flex align-items-stretch mb-2">
          <Card
            className="w-100 border-primary"
            role="button"
            onClick={() => navigate('/assistant')}
          >
            <Card.Body className="d-flex align-items-center justify-content-center flex-column">
              <Card.Title>Create a new Assistant</Card.Title>
              <Button variant="secondary">Create Assistant</Button>
            </Card.Body>
          </Card>
        </Col>

        {assistants.rows.map((assistant) => (
          <Col key={assistant.id} className="d-flex align-items-stretch mb-2">
            <Card role="button" onClick={() => navigate(`/assistant/${assistant.id}`)}>
              <Card.Img variant="top" src="https://www.jea.com/cdn/images/avatar/avatar-alt.svg" />
              <Card.Body>
                <Card.Title>{assistant.name}</Card.Title>
                <Card.Text>{assistant.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
