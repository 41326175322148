import React, {
  useCallback,
  useState,
} from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  useParams,
} from 'react-router-dom';

export default function ChatView() {
  const { assistantId } = useParams();
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState('');
  const [lastQuery, setLastQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChat = useCallback((evt) => {
    evt.preventDefault();

    const fetchData = async () => {
      try {
        const res = await fetch(`/api/assistant/${assistantId}/completion/public`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            prompt: query,
          }),
        });
        const data = await res.json();

        setMessage(data);
        setLastQuery(query);
        setQuery('');
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    setError('');
    setLoading(true);
    fetchData();
  }, [query]);
  return (
    <>
      <Navbar bg="primary" variant="dark" expand="md" className="mb-4">
        <Container fluid>
          <Navbar.Brand>Web A.I.</Navbar.Brand>
          <Navbar.Toggle aria-controls="header-nav-bar" />
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col md="3">
            <Card className="mb-3">
              <Card.Img src="https://cdn.listingphotos.sierrastatic.com/pics3x/v1720638389/111/111_224020420_01.jpg" variant="top" />
              <Card.Body>
                <Card.Title>Preserve at Rocky Fork</Card.Title>
                <Card.Text>
                  Ask questions about the rules and by-laws of the Home Owner&apos;s Assocation
                  of the Preserve at Rocky Fork.  Our agents will answer any question you may
                  have about the property in a few moments.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={{ offset: 1, span: 4 }}>
            <Card className="mb-3">
              <Card.Header>Ask our Agent</Card.Header>
              <Card.Body>
                {error && (
                  <Alert className="mb-4 mt-2" variant="danger">An error occurred. Please try again.</Alert>
                )}
                {!loading && !lastQuery && !error && (
                  <Card.Text className="text-center p-4 mb-3">
                    Our Agents are on stand-by to answer any questions you have about the property.
                  </Card.Text>
                )}
                {lastQuery && !error && (
                <ListGroup className="mb-3">
                  <ListGroup.Item className="p-4" active>{lastQuery}</ListGroup.Item>
                </ListGroup>
                )}
                {loading && !error && (
                <div className="text-center mb-3 p-4">
                  <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                </div>
                )}
                {!loading && message && (
                  <ListGroup className="mb-4">
                    <ListGroup.Item className="p-4">{message}</ListGroup.Item>
                  </ListGroup>
                )}
                {!message && !lastQuery && !error && (
                <Form onSubmit={handleChat}>
                  <InputGroup>
                    <Form.Control
                      placeholder="Ask a question..."
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                      disabled={loading}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Send
                    </Button>
                  </InputGroup>
                </Form>
                )}
                {lastQuery && (
                  <Button
                    className="text-center w-100"
                    variant="primary"
                    onClick={() => {
                      setLastQuery('');
                      setMessage('');
                      setError(false);
                      setLoading(false);
                    }}
                  >
                    Ask another question
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  );
}
