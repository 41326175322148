import React from 'react';

import {
  Link,
} from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

export default function SideNav() {
  return (
    <nav className="side-nav">
      <Container fluid>
        <Row>
          <Col className="p-0">
            <Accordion
              flush
              alwaysOpen
              defaultActiveKey={['0', '1']}
            >
              <Accordion.Item eventKey="0" className="bg-transparent">
                <Accordion.Header>Teams</Accordion.Header>
                <Accordion.Body className="px-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item as={Link} to="/team" className="px-4 bg-transparent text-white bg-hover-white">My First Team</ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-transparent">
                <Accordion.Header>Manage</Accordion.Header>
                <Accordion.Body className="px-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item as={Link} to="/assistants" className="px-4 bg-transparent text-white border-0 bg-hover-white">Assistants</ListGroup.Item>
                    <ListGroup.Item className="px-4 bg-transparent text-white border-0 bg-hover-white">Teams</ListGroup.Item>
                    <ListGroup.Item as={Link} to="/reports" className="px-4 bg-transparent text-white border-0 bg-hover-white">Reports</ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <ListGroup variant="flush" className="border-top border-dark-subtle">
              <ListGroup.Item className="bg-transparent text-white border-0 bg-hover-white">Billing</ListGroup.Item>
              <ListGroup.Item className="bg-transparent text-white border-0 bg-hover-white">Support</ListGroup.Item>
              <ListGroup.Item className="bg-transparent text-white border-0 bg-hover-white">Settings</ListGroup.Item>
              <ListGroup.Item className="bg-transparent text-white border-0 bg-hover-white">API</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </nav>
  );
}
