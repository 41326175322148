import React, {
  useState,
} from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  Form as RouterForm,
  Link,
  useLoaderData,
} from 'react-router-dom';

import AssistantCard from '../../components/assistant-card/assistant-card';

export default function EmbeddingView() {
  const [assistant, embedding] = useLoaderData();
  const [enableEmbedding, setEnableEmbedding] = useState(!!assistant.embedding);
  const [embeddingText, setEmbeddingText] = useState(embedding?.embedding);

  return (
    <Container>
      <h1>Assistant</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistant.id}` }}>Assistant</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistant.id}/embedding` }} active>Embedding</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col md={3}>
          <AssistantCard assistant={assistant} />
        </Col>
        <Col md={9}>
          <Card className="mb-3">
            <Card.Header>Embedding</Card.Header>
            <Card.Body>
              <Form as={RouterForm} method="POST">
                <Form.Group className="mb-3">
                  <Form.Check
                    checked={enableEmbedding}
                    name="enableEmbedding"
                    label="Enable Embedding"
                    onChange={(e) => setEnableEmbedding(e.target.checked)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Embedding Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="embedding"
                    rows={15}
                    value={embeddingText}
                    onChange={(e) => setEmbeddingText(e.target.value)}
                    readOnly={!enableEmbedding}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">Save</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
