import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Line } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';

import Alert from 'react-bootstrap/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {
  Link,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';

import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';

import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';

import request from '../../utils/request';

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
);

export default function ReportView() {
  const assistants = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [usage, setUsage] = useState({
    labels: [],
    datasets: [],
  });
  const [completions, setCompletions] = useState({
    labels: [],
    datasets: [],
  });
  const assistantId = searchParams.get('assistantId');

  const assistantList = useMemo(() => {
    const list = [];
    assistants.forEach((a) => {
      a.rows.forEach((r) => {
        list.push(r);
      });
    });
    return list;
  }, [assistants]);

  const buildReport = useCallback((report, label) => {
    const labels = [];
    const values = [];
    report.forEach((r) => {
      labels.push(r.day);
      values.push(r.total);
    });
    return {
      labels,
      datasets: [
        {
          label,
          data: values,
          fill: true,
          tension: 0.1,
        },
      ],
    };
  }, []);

  useEffect(() => {
    setError(false);
    if (!assistantId) {
      return;
    }

    const fetchCompletion = async () => {
      const rv = await request(`/api/report/${assistantId}/completions?startDate=${startDate}&endDate=${endDate}`);
      const completionReport = buildReport(rv, 'Completions');
      setCompletions(completionReport);
    };

    const fetchUsage = async () => {
      const rv = await request(`/api/report/${assistantId}/usage?startDate=${startDate}&endDate=${endDate}`);
      const usageReport = buildReport(rv, 'Usage');
      setUsage(usageReport);
    };

    try {
      fetchCompletion();
      fetchUsage();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [assistantId, startDate, endDate]);

  return (
    <Container>
      <h1>Reports</h1>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
        {!assistantId && (
        <Breadcrumb.Item active>Select Assistant...</Breadcrumb.Item>
        )}
        {assistantId && (
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/assistant/${assistantId}` }}>{assistantList.find((a) => a.id === assistantId).name}</Breadcrumb.Item>
        )}
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/reports' }} active>Reports</Breadcrumb.Item>
      </Breadcrumb>
      <Form>
        <Row>
          <Col sm={{ offset: 1, span: 4 }}>
            <Form.Group className="mb-3">
              <Form.Label>Assistant</Form.Label>
              <Form.Select
                name="assistantId"
                onChange={(e) => setSearchParams({ assistantId: e.target.value })}
                defaultValue={assistantId}
              >
                <option value="">Choose...</option>
                {assistantList?.map((assistant) => (
                  <option
                    key={assistant.id}
                    value={assistant.id}
                  >
                    {assistant.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {loading && (
        <div className="text-center mt-4">
          <FontAwesomeIcon icon={faSpinner} spin size="4x" className="mb-3" />
          <h4>Loading...</h4>
        </div>
      )}
      {error && (
        <Alert className="text-center mt-4">
          <p>There was an error loading the report.</p>
        </Alert>
      )}
      {assistantId && (
        <>
          <hr />
          <Row>
            <Col sm="6">
              <h2>Completions</h2>
              <Line data={completions} />
            </Col>
            <Col sm="6">
              <h2>Token Usage</h2>
              <Line data={usage} />
            </Col>
          </Row>
        </>
      )}
      {!assistantId && !error && !loading && (
      <Row>
        <Col sm={{ offset: 1, span: 10 }}>
          <Card className="mt-4">
            <Card.Body>
              <Card.Text className="text-center">Select an Assistant to view reports.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      )}
    </Container>
  );
}
