import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function InfoIcon({ size, tooltip }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip className="position-fixed">{tooltip}</Tooltip>
      }
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        size={size}
        className="info-icon text-info"
      />
    </OverlayTrigger>
  );
}

InfoIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  size: PropTypes.string,
};

InfoIcon.defaultProps = {
  size: '1x',
};
