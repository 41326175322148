import React, {
  useEffect,
  useState,
} from 'react';
import {
  Outlet,
  useLoaderData,
} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import PermissionContext from '../../context/permission';
import ToastContext from '../../context/toast';
import PageHeader from '../page-header/page-header';
import SideNav from '../side-nav/side-nav';

export default function SiteLayout() {
  const user = useLoaderData();
  const [toast, setToast] = useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(!!toast);
  }, [toast]);

  return (
    <PermissionContext.Provider value={user.permissions}>
      <ToastContext.Provider value={setToast}>
        <header data-testid="site-header">
          <PageHeader user={user} />
        </header>
        <main className="main" data-testid="site-main">
          <Container fluid>
            <Row>
              <Col md={2} className="bg-primary side-nav-container d-none d-md-block p-0 border-end">
                <SideNav />
              </Col>
              <Col xs={12} md={10} className="pt-4">
                <Outlet />
              </Col>
            </Row>
          </Container>
        </main>
        <ToastContainer
          position="top-end"
          className="me-3 mt-3 position-fixed"
        >
          <Toast
            show={showToast}
            onClose={() => setToast(null)}
            delay={4000}
            bg={toast?.bg}
            autohide
          >
            <Toast.Header closeButton>
              <strong className="me-auto">{toast?.header}</strong>
            </Toast.Header>
            <Toast.Body>{toast?.body}</Toast.Body>
          </Toast>

        </ToastContainer>
      </ToastContext.Provider>
    </PermissionContext.Provider>
  );
}
