import React from 'react';
import {
  createBrowserRouter,
  redirect,
} from 'react-router-dom';

import request from './utils/request';

import ErrorElement from './components/error-element/error-element';
import Redirect from './components/redirect/redirect';
import ProtectedRoute from './components/protected-route/protected-route';

import AdminCreateUserView from './views/admin/create-user';
import AdminHomeView from './views/admin/home';
import AdminUserView from './views/admin/user';
import AdminUsersView from './views/admin/users';

import SiteLayout from './components/site-layout/site-layout';

import AssistantView from './views/assistant/assistant';
import AssistantsView from './views/assistant/assistants';
import ChatView from './views/assistant/chat';
import PublicChatView from './views/chat/chat';
import AssistantCreateView from './views/assistant/create';
import AssistantEmbeddingView from './views/assistant/embedding';
import HistoryView from './views/assistant/history';
import HomeView from './views/home/home';
import LoginView from './views/login/login';
import ReportView from './views/assistant/report';
import TeamView from './views/team/team';

const router = createBrowserRouter([
  {
    path: '/login',
    errorElement: <Redirect to="/login" />,
    element: <LoginView />,
  },
  {
    path: '/chat/:assistantId',
    element: <PublicChatView />,
  },
  {
    element: <SiteLayout />,
    errorElement: <ErrorElement />,
    loader: () => request('/api/user'),
    children: [
      {
        path: '/',
        element: <HomeView />,
        loader: () => request('/api/assistants'),
      },
      {
        path: '/assistants',
        element: <AssistantsView />,
        loader: () => request('/api/assistants'),
      },
      {
        action: (async ({ request: req }) => {
          const formData = await req.formData();
          const res = await request('/api/assistant', {
            method: 'POST',
            body: Object.fromEntries(formData),
          });
          return redirect(`/assistant/${res.id}`);
        }),
        path: '/assistant',
        element: <AssistantCreateView />,
        loader: () => Promise.all([
          request('/api/assistant/models'),
          request('/api/teams'),
        ]),
      },
      {
        action: (async ({ params, request: req }) => {
          const formData = await req.formData();
          const intent = formData.get('intent');

          if (intent === 'model-parameters') {
            return request(`/api/assistant/${params.assistantId}/parameters`, {
              method: 'PUT',
              body: Object.fromEntries(formData),
            });
          }

          if (intent === 'api-key') {
            return request(`/api/assistant/${params.assistantId}/key`, {
              method: 'POST',
              body: Object.fromEntries(formData),
            });
          }

          // Intent === 'assistant-information'
          return request(`/api/assistant/${params.assistantId}`, {
            method: 'PUT',
            body: Object.fromEntries(formData),
          });
        }),
        path: '/assistant/:assistantId',
        element: <AssistantView />,
        loader: ({ params }) => Promise.all([
          request(`/api/assistant/${params.assistantId}`),
          request(`/api/assistant/${params.assistantId}/keys`),
        ]),
      },
      {
        path: '/assistant/:assistantId/chat',
        element: <ChatView />,
        loader: ({ params }) => request(`/api/assistant/${params.assistantId}`),
      },
      {
        path: '/assistant/:assistantId/history',
        element: <HistoryView />,
        loader: ({ params }) => Promise.all([
          request(`/api/assistant/${params.assistantId}`),
          request(`/api/assistant/${params.assistantId}/history`),
        ]),
      },
      {
        path: '/reports',
        element: <ReportView />,
        loader: () => Promise.all([
          request('/api/assistants'),
        ]),
      },
      {
        path: '/assistant/:assistantId/embedding',
        element: <AssistantEmbeddingView />,
        loader: ({ params }) => Promise.all([
          request(`/api/assistant/${params.assistantId}`),
          request(`/api/assistant/${params.assistantId}/embedding`),
        ]),
        action: (async ({ params, request: req }) => {
          const formData = await req.formData();
          const enableEmbedding = formData.get('enableEmbedding') === 'on';

          return request(`/api/assistant/${params.assistantId}/embedding`, {
            method: 'POST',
            body: {
              ...Object.fromEntries(formData),
              enableEmbedding,
            },
          });
        }),
      },
      {
        path: '/team',
        element: <TeamView />,
      },
      {
        path: '/admin',
        element: <ProtectedRoute />,
        children: [
          {
            path: '/admin',
            element: <AdminHomeView />,
          },
          {
            path: '/admin/users',
            element: <AdminUsersView />,
            loader: () => request('/api/admin/user'),
          },
          {
            path: '/admin/user/:userId',
            element: <AdminUserView />,
            loader: ({ params }) => request(`/api/admin/user/${params.userId}`),
          },
          {
            path: '/admin/user',
            element: <AdminCreateUserView />,
          },
        ],
      },
    ],
  },
]);

export default router;
